.modal-overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  position: relative;

  h1 {
    font-family: "Fjalla One", sans-serif;
    margin: 0;
    font-size: 16px;
  }
  h2 {
    font-family: "Barlow Condensed", sans-serif;
  }

  p,
  span,
  a {
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin: 0;
    padding-top: 20px;
    padding-right: 35px;
    line-height: 25px;
    text-decoration: none;
  }
}

.modal-text {
  padding: 40px;
  margin-bottom: 5px;
  background: white;
  align-self: center;
  box-sizing: border-box;
}

.modal-images {
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    padding: 5px 0;
  }
}

@media (min-width: 767px) {
  .modal-text {
    width: 74.5%;
  }

  .modal-images {
    .image-wrapper {
      display: flex;
      justify-content: center;
      .medium + .small {
        width: 18%;
      }

      .small {
        width: 38%;
      }

      .medium {
        width: 56%;
        align-self: center;
        justify-self: center;
        margin-right: 10px;
      }
      .large {
        width: 74.5%;
      }
    }
  }
}

.modal-close-button {
  position: absolute;
  right: 32px;
  top: 17px;
  width: 32px;
  height: 32px;
  opacity: 0.7;
  cursor: pointer;
}
.modal-close-button:hover {
  opacity: 1;
}
.modal-close-button:before,
.modal-close-button:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: white;
}
.modal-close-button:before {
  transform: rotate(45deg);
}
.modal-close-button:after {
  transform: rotate(-45deg);
}
