.contact {
  p {
    margin-top: 15px;
  }
  .social-icon {
    margin-top: 15px;
    padding: 20px;
    height: 40px;
    width: 40px;
  }
}
