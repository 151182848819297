$xsm: "only screen and (min-width:375px)";
$sm: "only screen and (min-width:768px)";
$md: "only screen and (min-width:992px)";
$lg: "only screen and (min-width:1200px)";
$xlg: "only screen and (min-width:1400px)";
$xxlg: "only screen and (min-width:1800px)";
$xxlg-sm: "only screen and (min-width: 768px) and (max-width: 1800px)";

@mixin xsm() {
  @media #{$xsm} {
    @content;
  }
}
@mixin sm() {
  @media #{$sm} {
    @content;
  }
}
@mixin md() {
  @media #{$md} {
    @content;
  }
}
@mixin lg() {
  @media #{$lg} {
    @content;
  }
}

@mixin xlg() {
  @media #{$xlg} {
    @content;
  }
}

@mixin xxlg() {
  @media #{$xxlg} {
    @content;
  }
}

@mixin xxlg-sm() {
  @media #{$xxlg-sm} {
    @content;
  }
}

@mixin ie {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}
