.about {
  @include headline-top-chevron-bottom;

  p {
    line-height: 35px;
    font-size: 17px;
  }

  .presentation {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
  }

  .profile-image {
    padding-bottom: 10px;
    img {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  @include sm {
    text-align: left;
    h1 {
      top: 40px;
    }
    p {
      margin-top: 50px;
      font-size: 20px;
    }
    .presentation {
      margin-top: 60px;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0;
      .profile-image {
        margin-right: 80px;
        img {
          height: 200px;
          width: 200px;
        }
      }
    }
  }
}
