@import "../foundation/mixins";
@import "../foundation/colors";

.work {
  @include headline-top-chevron-bottom;
  height: 100%;

  @include sm {
    height: 100vh;
  }

  img {
    width: 200px;
    height: 200px;
  }

  .gallery {
    margin: 7rem 0;

    @include md {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }
  }

  .gallery-image-wrapper {
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 1rem;
    @include md {
      margin-bottom: 0;
    }
  }

  .see-work {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    button {
      z-index: 99;
      background-color: $font-color-secondary;
      color: white;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      opacity: 0.7;

      @include md {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.5);
        transition: all 350ms ease-in-out;
      }
    }
  }
  .gallery-image-wrapper:hover .see-work {
    transition: background-color 0.5s ease;
    background-color: rgba(0, 0, 0, 0.1);
    button {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  .gallery-image {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  }
}
