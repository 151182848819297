@import "../scss/foundation/responsive";
@import "../scss/foundation/layout";
@import "../scss/foundation/colors";

body {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: "Fjalla One", sans-serif;
  margin: 0;
}

h2,
button {
  font-family: "Barlow Condensed", sans-serif;
}

p {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}

.landing-page,
.work,
.about,
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  max-width: 100%;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  position: relative;
  text-align: center;
}

.scroll-title,
.scroll-up p {
  color: $font-color-secondary;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}
